import React from 'react';

const Footer = () => {
  return (
    <footer className="text-white p-4 text-center">
        <p className='font-mono'>&copy; 2024 Mattias Orahem</p>
    </footer>
  );
};

export default Footer;
