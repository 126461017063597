// src/pages/Home.js
import React from 'react';
import HeroSection from '../components/Home/HeroSection';
import Footer from '../components/General/Footer';
import Header from '../components/General/Header';

const Home = () => {
  return (
    <div className='bg-neutral-950'>
      <Header />
      <HeroSection />
      <Footer />
    </div>
  );
};

export default Home;