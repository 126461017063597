import React from 'react';
import Header from '../components/General/Header.js';
import Footer from '../components/General/Footer.js';
import ContactPageHeroSection from '../components/ContactPage/ContactPageHeroSection.js';

const ProcessPage = () => {
  return (
    <div className='bg-neutral-950'>
      <Header />
      <ContactPageHeroSection />
      <Footer />
    </div>
  );
};

export default ProcessPage;