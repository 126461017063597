import React from 'react';

const ContactPageHeroSection = () => {
  return (
    <section className="text-white py-20 min-h-screen flex flex-col items-center justify-center font-mono bg-blue-500 bg-opacity-5">
      <div className="text-center max-w-lg px-6">
        <h1 className="text-5xl mb-4 font-bold">Let's Connect!</h1>
        <p className="text-2xl mb-8">I'd love to hear from you, so feel free to reach out to me.</p>
        <div className="flex flex-col md:flex-row justify-center gap-6 mt-8">
          <a 
            href="mailto:mattias.orahem@gmail.com"
            className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            Email Me!
          </a>
        </div>
      </div>
      <p className="mt-10 font-sans text-lg font-semibold">I'm also avalable to take your call</p>
      <a href="tel:+46739458237" className="text-lg font-bold text-blue-500 hover:text-white underline">+46-73 945 82 37</a>        
    </section>
  );
};

export default ContactPageHeroSection;
