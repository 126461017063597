import React from 'react';
import { Link } from 'react-router-dom';

import {ReactComponent as GitHub} from '../../icons/github.svg'
import {ReactComponent as Email} from '../../icons/email.svg'
import {ReactComponent as Logo} from '../../icons/logo.svg'


const Header = () => {
  return (
    <header className="bg-neutral-950 bg-opacity-95 font-mono text-white  p-2.5 fixed top-0 left-0 w-full z-50 transform-gpu transition ease-out-cubic duration-300">
      <div className="container m-auto flex items-center justify-between max-w-7xl">

        <div className="flex-shrink-0">
          <Link to="/">
            <Logo className="w-7 h-7 text-blue-500 duration-300 hover:text-white"/>
          </Link>
        </div>


        <nav className="flex pt-3">
          <ul className="flex space-x-7">
            <li><Link to="/aboutpage" className="duration-300 hover:text-blue-500 text-sm">about</Link></li>
            <li>
              <Link to="/contactpage">
                <Email className="w-7 h-7 text-blue-500 duration-300 hover:text-white"/>
              </Link>
            </li>
            <li>
              <Link to="https://github.com/Cocowhal">
                <GitHub className="w-6 h-7 text-blue-500 duration-300 hover:text-white"/>
              </Link>
            </li>

          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
