import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollMagic from 'scrollmagic';

import webimg from '../../images/webdesighn.webp';
import meimg from '../../images/me.webp';
import archimg from '../../images/arch.webp';

const HeroSection = () => {
  const headlineRef = useRef(null);
  const subtextRef = useRef(null);
  const nameRef = useRef(null);
  const spineRef = useRef(null);

  // Use unique refs for each section
  const sectionWhatIDoRef = useRef(null);
  const sectionMyStartRef = useRef(null);
  const sectionProgramming1Ref = useRef(null);
  const sectionWebDevelopmentRef = useRef(null);
  const sectionTechStackRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: 'power2.out' } });

    tl.fromTo(
      nameRef.current.querySelector('.overlay'),
      { x: '-100%' },
      { x: '100%', duration: 1.3, backgroundColor: '#3b82f6', delay: 1 },
      'start'
    )
      .fromTo(
        nameRef.current.querySelector('.text'),
        { opacity: 0 },
        { opacity: 1 },
        '-=1.2'
      );

    tl.fromTo(
      headlineRef.current.querySelector('.overlay'),
      { x: '-100%' },
      { x: '100%', duration: 1.3, backgroundColor: '#3b82f6', delay: 1 },
      'start+=0.6'
    )
      .fromTo(
        headlineRef.current.querySelector('.text'),
        { opacity: 0 },
        { opacity: 1 },
        '-=1.09'
      );

    tl.fromTo(
      subtextRef.current.querySelector('.overlay'),
      { x: '-100%' },
      { x: '100%', duration: 1.3, backgroundColor: '#3b82f6', delay: 1 },
      'start+=0.3'
    )
      .fromTo(
        subtextRef.current.querySelector('.text'),
        { opacity: 0 },
        { opacity: 1 },
        '-=1.4'
      );

    tl.fromTo(
      spineRef.current,
      { height: '0%' },
      { height: '80%', duration: 5, ease: 'power2.out' },
      'start+=3'
    );

    const controller = new ScrollMagic.Controller();

    const fadeInAnimation = (element) => {
      gsap.fromTo(
        element,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1, ease: 'power2.out' }
      );
    };

    // Use the updated refs
    new ScrollMagic.Scene({
      triggerElement: sectionWhatIDoRef.current,
      triggerHook: 0.8,
      reverse: false,
    })
      .on('enter', () => fadeInAnimation(sectionWhatIDoRef.current))
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: sectionMyStartRef.current,
      triggerHook: 0.8,
      reverse: false,
    })
      .on('enter', () => fadeInAnimation(sectionMyStartRef.current))
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: sectionProgramming1Ref.current,
      triggerHook: 0.8,
      reverse: false,
    })
      .on('enter', () => fadeInAnimation(sectionProgramming1Ref.current))
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: sectionWebDevelopmentRef.current,
      triggerHook: 0.8,
      reverse: false,
    })
      .on('enter', () => fadeInAnimation(sectionWebDevelopmentRef.current))
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: sectionTechStackRef.current,
      triggerHook: 0.8,
      reverse: false,
    })
      .on('enter', () => fadeInAnimation(sectionTechStackRef.current))
      .addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);

  return (
      <section className="relative text-gray-200 font-mono py-20 min-h-[calc(100vh-6rem)] flex flex-col bg-blue-500 bg-opacity-5">
        <div
          ref={spineRef}
          className="spine absolute left-[10rem] top-[20rem] bottom-0 w-1 bg-gradient-to-b from-blue-600 via-blue-500 to-blue-200 shadow-lg rounded-full md:opacity-0 lg:opacity-100"
        ></div>

        <div className="relative h-[600px] flex ml-[20rem] mb-[20rem]">
          <div className="absolute bottom-10 left-10 text-left">
            <h2 ref={headlineRef} className="text-7xl font-bold mb-2 relative overflow-hidden">
              <span className="overlay absolute inset-0 bg-blue-500 z-10"></span>
              <span className="text relative z-0">
                orahem<span className="text-blue-500">.com</span>
              </span>
            </h2>
            <p ref={nameRef} className="text-2xl text-blue-500 mb-4 relative overflow-hidden">
              <span className="overlay absolute inset-0 bg-blue-500 z-10"></span>
              <span className="text relative z-0">Mattias Orahem</span>
            </p>
            <p ref={subtextRef} className="text-xl font-bold relative overflow-hidden">
              <span className="overlay absolute inset-0 bg-blue-500 z-10"></span>
              <span className="text relative z-0">Passionate frontend developer</span>
            </p>
          </div>
        </div>

        <div className="container mx-auto text-white font-mono">
          {/* "What I Do" Section */}
          <div className="flex flex-row items-center mb-[20rem] opacity-0" ref={sectionWhatIDoRef}>
            <div className="w-1/2 p-4">
              <h2 className="text-3xl font-bold mb-3">&lt;what i do&gt;</h2>
              <p className="text-lg">
                I craft websites that are visually appealing, snappy, and intuitive. My goal is to deliver unique designs that engage users and provide seamless interactions. I build eye-catching user interfaces while ensuring a smooth experience for every visitor.
              </p>
            </div>
            <div className="w-1/2 p-4">
              <img
                src={webimg}
                alt="Summery"
                loading="lazy"
                className="w-auto h-1/2 rounded-3xl"
                width="400"
                height="300"
              />
            </div>
          </div>

          <div className="opacity-0 mb-[20rem]" ref={sectionMyStartRef}>
            <div className="flex flex-row items-center -mb-[2rem]">
              <div className="w-1/2 p-4">
                <h2 className="text-3xl mb-3 font-bold">&lt;jurney&gt;</h2>
                <p className="text-lg">
                  My journey began during my first year of high school with a course on game development using Unity. It was there that I discovered my passion for programming and decided it would be my lifelong pursuit.
                  <br /><br />
                  I excelled in programming, starting with AI and data science projects, messing around with Linux servers, as well as embedded programming in C++. Later, I delved into web development and web server programming, where I realized my true passion for frontend development.
                </p>
              </div>
              <div className="w-1/2 p-4">
                <img
                  src={archimg}
                  alt="First time installing Arch Linux on my laptop"
                  loading="lazy"
                  className="w-auto h-[25rem] rounded-3xl shadow-lg"
                  width="300"
                  height="225"
                />
              </div>
            </div>

            <div className="flex flex-row items-center">
              <div className="w-1/2 mr-[10rem] order-2">
                <p className="text-lg">
                  Now, I'm an aspiring front-end developer who loves designing, programming, and maintaining websites. My passion is boundless, and my enthusiasm for creating exceptional web experiences grows every day.
                  <br /> <br />
                  I dive into the details to craft designs that are both visually stunning and intuitive. It feels like my calling to make things look amazing while ensuring they function flawlessly.
                  <br /> <br />
                  Keeping up with the latest tech trends and innovations is my favorite pastime, from new computer components to cutting-edge software. I'm always eager to learn and experiment with the latest and greatest.
                </p>
              </div>
              <div className="w-1/2 p-4 order-1">
                <img
                  src={meimg}
                  alt="my desktop"
                  loading="lazy"
                  className="w-auto h-[25rem] rounded-3xl shadow-lg"
                  width="300"
                  height="225"
                />
              </div>
            </div>
          </div>

          {/* "Tech Stack" Section */}
          <div className="opacity-0 mb-[10rem]" ref={sectionTechStackRef}>
            <div className="w-1/2 p-4">
              <h3 className="text-3xl font-semibold mb-3">&lt;tech stack&gt;</h3>
              <p className='font-bold text-lg pb-[2rem]'>This website was created from the ground up with:</p>
              <div className="grid grid-cols-2 gap-[4rem] mb-10 w-[23rem] text-lg">
                <p className="col-span-1">JavaScript, Tailwind CSS, HTML, React</p>
                <p className="col-span-1">Photoshop, Illustrator</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default HeroSection;
