import React, { useEffect, useRef } from 'react';
import ScrollMagic from 'scrollmagic';
import gsap from 'gsap';

import myImage from '../../images/white.webp';

const AboutPageHeroSection = () => {
  const imageRef = useRef(null);
  const h1Ref = useRef(null);
  const textRef = useRef(null);
  const controllerRef = useRef(null);

  useEffect(() => {
    // GSAP animation for text elements
    gsap.fromTo(
      textRef.current.children,
      { opacity: 0, x: -100 },
      { opacity: 1, x: 0, duration: 1, ease: 'power2.out', delay: 2 }
    );
  }, []);

  useEffect(() => {
    // GSAP animation for h1 element
    gsap.fromTo(
      h1Ref.current,
      { opacity: 0, scale: 0.5 },
      { opacity: 1, scale: 1, duration: 1.1, ease: 'power2.out', delay: 1 }
    );
  }, []);

  useEffect(() => {
    // Initialize ScrollMagic controller
    controllerRef.current = new ScrollMagic.Controller();

    // Create a ScrollMagic scene
    const scene = new ScrollMagic.Scene({
      triggerElement: imageRef.current,
      triggerHook: 'onEnter',
      duration: '100%', // Adjust this if you want the image to stay pinned for a duration
    })
      .setPin(imageRef.current) // Pin the image element
      .addTo(controllerRef.current); // Add scene to ScrollMagic controller

    // Cleanup function to remove the controller and scene when component unmounts
    return () => {
      scene.destroy(true);
      controllerRef.current.destroy(true);
    };
  }, []);

  return (
    <>
      <style>
        {`
          @media screen and (max-width: 768px) {
            .image-container {
              top: 50% !important;
              left: 50% !important;
              transform: translate(-50%, -50%) !important;
              width: 110vw !important;
              height: 110vw !important;
            }
            .text-container {
              margin-left: 1rem !important;
              margin-top: 10rem !important;
              width: 90% !important;
              padding: 1rem !important;
            }
            h1 {
              font-size: 2.5rem !important;
            }
            p, h2 {
              font-size: 1rem !important;
            }
            .grid {
              grid-template-columns: 1fr !important;
              gap: 1rem !important;
            }
            .grid > p {
              font-size: 0.9rem !important;
            }
          }
        `}
      </style>
      <section className="relative min-h-screen overflow-hidden flex bg-blue-500 bg-opacity-5">
        <div
          className="absolute top-1/2 transform -translate-y-[107%] flex items-center justify-center opacity-85 image-container"
          style={{ left: '-15%', width: '85vh', height: '85vh' }}
          ref={imageRef}
        >
          <img
            src={myImage}
            alt="About Me"
            className="object-cover w-full h-full rounded-full"
            style={{ borderRadius: '50%' }}
          />
        </div>

        <div className="ml-[27rem] mt-[16rem] relative w-[70%] flex flex-col justify-start p-8 md:p-16 md:text-left font-mono text-xl text-gray-400 text-container">
          <h1 ref={h1Ref} className="text-7xl font-bold mb-4 mix-blend-difference text-white">
            about
          </h1>

          <div ref={textRef} className="mix-blend-difference">
            <p className="justify-start text-xl font-bold mb-[1rem] text-blue-500 max-w-[40rem]">
              Frontend developer from Stockholm. Inspired by fiction, art and music.
            </p>

            <p className="text-xl mb-10">&lt;Freelance Frontend Developer&gt;</p>

            <h2 className="text-2xl font-bold mb-4 text-white">skills</h2>
            <div className="grid grid-cols-4 gap-[2rem] mb-10">
              <p className="text-lg col-span-1">Frontend, UX/UI</p>
              <p className="text-lg col-span-1">JavaScript, CSS, HTML, React, Node.js, C++, Python</p>
              <p className="text-lg col-span-1">Photoshop, Illustrator, Sketching</p>
            </div>

            <h2 className="text-2xl font-bold mb-4 text-white">projects</h2>
            <div className="grid grid-cols-4 gap-[2rem] mb-10">
              <p className="text-lg col-span-1">
                {' '}
                <a
                  href="https://github.com/Cocowhal/bccd_algorytm"
                  className="text-blue-600 font-bold duration-300 hover:text-white"
                >
                  &lt;Blood cell identifier&gt;
                </a>
                <br />
                Using the YOLOv5 vision model to train an AI to accurately locate and identify blood cells.
              </p>
              <p className="text-lg col-span-1">
                {' '}
                <a
                  href="https://github.com/Cocowhal/linkedList"
                  className="text-blue-600 font-bold duration-300 hover:text-white"
                >
                  &lt;Linked List&gt;
                </a>
                <br />
                A list where each item is a pointer to the next item, functioning as individual memory addresses.
              </p>
              <p className="text-lg col-span-1">
                {' '}
                <a
                  href="https://github.com/Cocowhal/creditcard_fraud"
                  className="text-blue-600 font-bold duration-300 hover:text-white"
                >
                  &lt;Credit Card Fraud Detection&gt;
                </a>
                <br />
                Identifying fraud in European credit card transactions with data science.
              </p>
            </div>

            <h2 className="text-2xl font-bold mb-4 text-white">languages</h2>
            <div className="grid grid-cols-4 gap-[2rem] mb-10">
              <p className="text-lg col-span-1">
                {' '}
                <strong className="text-white">&lt;fluent&gt;</strong>
                <br />
                English
                <br />
                Swedish
                <br />
                Assyrian
              </p>
              <p className="text-lg col-span-1">
                {' '}
                <strong className="text-white">&lt;intermediate&gt;</strong>
                <br />
                German
              </p>
            </div>

            <h2 className="text-2xl font-bold mb-4 text-white">i'm also</h2>
            <div className="grid grid-cols-4 gap-[2rem]">
              <p className="text-lg col-span-1">Petting my dog</p>
              <p className="text-lg col-span-1">Reading fiction</p>
              <p className="text-lg col-span-1">Drawing</p>
              <p className="text-lg col-span-1">Drinking coffee</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPageHeroSection;
