import React from 'react';
import Header from '../components/General/Header';
import Footer from '../components/General/Footer';
import AboutPageHeroSection from '../components/AboutPage/AboutPageHeroSection';


const AboutPage = () => {
  return (
    <div className='bg-neutral-950'>
      <Header />
      <AboutPageHeroSection />
      <Footer />
    </div>
  );
};

export default AboutPage;